export const UI_MENU_BASE_FRAGMENT = `
  fragment uiMenuBaseFragment on UiMenu {
        uid
        schemaCode
        name
        code
        production
        active
        version
        items(
          filter: {
            OR: [
              {active: null}
              {active: true}
            ]
          }
          orderBy: displaySequence_asc
        ) {
            uid
            type
            active
            payload
            displaySequence
            parentMenuItem {
                uid
            }
            subMenuItem(
              filter: {
                OR: [
                  {active: null}
                  {active: true}
                ]
              }
              orderBy: displaySequence_asc
            ) {
                uid
                type
                active
                displaySequence
                payload
            }
        }
  }
`;
